function renderBanner() {
  const bannerList = window.banner;
  const indexContainer = $("#index-banner.swiper-wrapper");
  const isLoggedIn = $(".before-login.hide").length !== 0;
  bannerList &&
    bannerList.length !== 0 &&
    bannerList.forEach(function (item, index) {
      const { imageUrl, redirectTitle, redirectUrl, redirectKey, redirectGroup, redirectPage, bannerType } = item;
      if (imageUrl) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */

        const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
                <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
                <img class="img-bg" src="${imageUrl}" />
            </div>
          `);
        banner.click(function () {
          if (redirectGroup === 1) {
            /**
             * Aff
             * @type {string}
             */
            if ((window.themeName && window.themeName.includes("ydl")) || window.themeName.includes("wdl")) {
              location.href = "register.html";
            } else {
              location.href = "affindex.html";
            }
          } else {
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            });
          }
        });

        /**
         * index append 首页type banner, promo append 优惠活动type banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
        }
      }
    });

  if ($(".banner .swiper-container").length !== 0) {
    if (bannerList && bannerList.length) {
      var swiper = new Swiper(".banner .swiper-container", {
        autoHeight: true, //enable auto height
        pagination: {
          el: ".banner .swiper-pagination",
        },
        loop: true,
        autoplay: true,
      });
    }
  }
}

function bindOpenModalFunction(selector, target) {
  $(selector).attr("data-custom-open", target);
}

$(document).ready(function () {
  if (Pace) {
    Pace.on("done", renderBanner);
  } else {
    $(window).load(renderBanner);
  }

  // modal
  bindOpenModalFunction(".nav #menu-register-button", "modal-register");
  bindOpenModalFunction(".top-login #forgot-pw-button", "modal-forget-password");

  MicroModal.init({
    onShow: (modal) => modalCallback(modal.id),
    openTrigger: "data-custom-open",
    debugMode: true,
    awaitCloseAnimation: true,
  });

  // hash detection
  $(function () {
    function detectHash() {
      var currentHash = location.hash && location.hash.slice(1);
      if (currentHash.length !== 0 && currentHash != "!") {
        var faqContent = ".faq-content-col .faq-content[data-hash=" + currentHash + "]";
        var span = ".faq-menu span[data-hash=" + currentHash + "]";
        $(faqContent).show().siblings().hide();
        $(span).addClass("active").siblings().removeClass("active");
      }
    }

    detectHash();
  });

  // faq tab
  $(".faq-menu span, .footer-item a").on("click", function () {
    var currentHash = $(this).attr("data-hash");
    var faqContent = ".faq-content-col .faq-content[data-hash=" + currentHash + "]";
    window.location.hash = currentHash;
    $(faqContent).show().siblings().hide();
    $(this).addClass("active").siblings().removeClass("active");
  });

  // count animation
  if ($(".countAnimate").length !== 0) {
    $(".countAnimate").each(function () {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 2000,
            easing: "swing",
            step: function (now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  }

  // mask svg
  function renderSvgMask() {
    $(".svg-mask").each(function () {
      var currentDiv = $(this);
      var currentImage = $(this).find("img").attr("src");
      currentDiv.append(
        $(
          `<div class="image" style="mask-image: url(${currentImage}); -webkit-mask-image: url(${currentImage})"></div>`
        )
      );
      currentDiv.find("img").remove();
    });
  }
  renderSvgMask();

  //promo
  $(function () {
    if (window.promo) {
      renderPromo(window.promo);
    } else {
      Object.defineProperty(window, "promo", {
        set: function (data) {
          renderPromo(data);
        },
      });
    }

    function registerPromo(promoId) {
      api.registerPromo(promoId);
    }

    function renderPromo(promoData) {
      if (Array.isArray(promoData)) {
        var promoWrapper = $(".promo-wrapper");
        promoData.forEach(function (value, index) {
          var promoItem = $(`<div class="main_box" data-type="${value.type}"></div>`);
          var promoId = value.promoId;
          var requestToJoin = value.requestToJoin;
          var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

          promoItem.append(
            $(`
              ${
                value.imagePath
                  ? `
                  <div class="icon sub-content-item-header">
                    <img src="${value.imagePath}" />
                  </div>
                `
                  : `
                  <div class="sub-content-item-header">
                    <h3>${value.title}</h3>
                  </div>
                `
              }
              <div class="text nr" style="display: none;">
                ${value.content}
                ${
                  requestToJoin
                    ? `
                             <div class="sub-content-item-footer">
                               ${
                                 firstDepositPromoJoinMsg
                                   ? `
                                     <div class="sub-content-item-footer--info">
                                       ${firstDepositPromoJoinMsg}
                                     </div>
                                   `
                                   : `
                                     <button
                                       class="sub-content-item-footer--register-promo btn-color"
                                       data-id="${promoId}"
                                     >
                                       申请参与
                                     </button>
                                   `
                               }
                             </div>
                           `
                    : ``
                }
              </div>
            `)
          );
          promoWrapper.append(promoItem);
        });

        $(document).on("click", ".main_box .sub-content-item-header", function (e) {
          e.preventDefault();
          var mainbox = $(this).closest(".main_box");
          if (mainbox.hasClass("on")) {
            mainbox.removeClass("on");
            mainbox.children(".bt").show();
            mainbox.children(".nr").slideUp();
          } else {
            mainbox.addClass("on");
            mainbox.children(".bt").hide();
            mainbox.children(".nr").slideDown();
          }
        });

        $(document).on("click", ".sub-content-item-footer--register-promo", function (e) {
          e.preventDefault();
          var promoId = $(this).data("id");
          registerPromo(promoId);
        });
      }
    }
  });
});
